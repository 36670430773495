.speech-bubble:after {
  content: "";
  position: absolute;
  top: 13px;
  left: 50%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-bottom-color: #dc3545;
  border-top: 0;
  margin-left: -20px;
  margin-top: -20px;
}
.invalid-feedback {
  width: 50% !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  padding-bottom: 2px;
  padding-top: 2px;
}
